import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { RouterService } from "../../../core/services/router.service";

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.scss'
})
export class NotFoundComponent {
    companyLogo: string = "assets/icons/exafyLogo.svg"
    errorImage: string = "assets/images/notFound.png"
    statusCode: string = "404"
    errorDescription: string = "Go back to homepage."
    homepageButton: string = "Back to Homepage"

    constructor (private title: Title, private router: RouterService) {
        this.title.setTitle('Not Found');
    }

    onBackHomepageClick() {
        this.router.goToLogin();
    }
}
