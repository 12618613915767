import { Component } from '@angular/core';

import { AlertService } from "../../../core/services/alert.service";
import { AuthService } from "../../../core/services/auth.service";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {
    welcomeText: string= 'Welcome!';
    loginText: string = 'Admin Panel Login';
    usernamePlaceholder: string = 'Username';
    passwordPlaceholder: string = 'Password';
    forgotPasswordText: string = 'Forgot password?';
    loginButton: string = 'Login';

    forgotTitle: string = 'Forgot Password?';
    forgotText: string = 'Enter your email address that you use with your account to continue.';
    emailPlaceholder: string = 'Email';
    sendButton: string = 'Send';
    signUpButton: string = 'Sign Up';

    logo: string = "assets/icons/exafyLogo.svg";

    username: string = '';
    password: string = '';
    email: string = '';
    isPasswordForgotten: boolean = false;


    constructor(
        private alert: AlertService,
        private auth: AuthService,
        private title: Title) {
        this.title.setTitle('Login');
    }

    async login() {
        await this.performLogin();
    }

    async send() {
        await this.performForgotPassword();
    }

    signUp() {
        this.openLogin();
    }

    forgotPassword() {
        this.openForgotPassword();
    }

    openLogin() {
        this.email = '';
        this.isPasswordForgotten = false;
    }

    openForgotPassword() {
        this.username = '';
        this.password = '';
        this.isPasswordForgotten = true;
    }

    formFilled(): boolean {
        if (!this.username || this.username.trim() === "" || !this.password) return false;

        return true;
    }

    emailFilled(): boolean {
        if (!this.email || this.email.trim() === "") return false;

        return true;
    }

    async performForgotPassword() {
        this.alert.showLoading();

        if (!this.email) {
            return;
        }

        const emailSent = await this.auth.forgotPassword(this.email);

        if (emailSent) {
            this.openLogin();
            return;
        }
    }

    async performLogin() {
        this.alert.showLoading();

        if (!this.username || !this.password) {
            return;
        }

        await this.auth.login(this.username, this.password);
    }
}
