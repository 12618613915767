import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Assistant } from "../../models/assistant.model";

// Testing app without server support
// Intercepts HTTP requests and returns a predefined (mocked) response
@Injectable()
export class MockInterceptor implements HttpInterceptor {
    private assistants: Assistant[] = [];
    private assistant: Assistant = {
        id: '2569874589',
        name: 'Pera Asistent',
        role: 'Helper',
        knowledgeScope: 'Controlled',
        similarityThreshold: 75,
        communicationStyle: 'Informal',
        behavior: 'Creative',
        answerLength: 100,
        selectedLanguages: [{ name: 'English', locale: 'en-US', voice: 'Male' }, { name: 'German', locale: 'de-DE', voice: 'Female' }],
        shortcuts: {
            first: 'Shortcut 1',
            second: 'Shortcut 2',
            third: ' Shortcut 3',
            fourth: 'Shortcut 4'
        }
    }

    constructor() {
        this.assistants = [
            {
                id: '5241',
                name: 'Pera Asistent',
                role: 'Helper',
                knowledgeScope: 'Controlled',
                similarityThreshold: 75,
                communicationStyle: 'Formal',
                behavior: 'Friendly',
                answerLength: 100,
                selectedLanguages: [{ name: 'English', locale: 'en-US', voice: 'Female' }],
                shortcuts: {
                    first: 'Ctrl+1',
                    second: 'Ctrl+2',
                    third: 'Ctrl+3',
                    fourth: 'Ctrl+4'
                }
            },
            {
                id: '5741',
                name: 'M Asistent',
                role: 'Advisor',
                knowledgeScope: 'Mixed',
                similarityThreshold: 60,
                communicationStyle: 'Informal',
                behavior: 'Curious',
                answerLength: 130,
                selectedLanguages: [{ name: 'Spanish', locale: 'es-ES', voice: 'Male' }],
                shortcuts: {
                    first: 'Ctrl+Shift+1',
                    second: 'Ctrl+Shift+2',
                    third: 'Ctrl+Shift+3',
                    fourth: 'Ctrl+Shift+4'
                }
            },
            {
                id: '527741',
                name: 'J Asistent',
                role: 'Guide',
                knowledgeScope: 'Controlled',
                similarityThreshold: 80,
                communicationStyle: 'Formal',
                behavior: 'Creative',
                answerLength: 120,
                selectedLanguages: [{ name: 'French', locale: 'fr-FR', voice: 'Female' }],
                shortcuts: {
                    first: 'Alt+1',
                    second: 'Alt+2',
                    third: 'Alt+3',
                    fourth: 'Alt+4'
                }
            },
            {
                id: '529941',
                name: 'N Asistent',
                role: 'Sef',
                knowledgeScope: 'Controlled',
                similarityThreshold: 80,
                communicationStyle: 'Formal',
                behavior: 'Creative',
                answerLength: 80,
                selectedLanguages: [{ name: 'French', locale: 'fr-FR', voice: 'Male' }],
                shortcuts: {
                    first: 'Alt+1',
                    second: 'Alt+2',
                    third: 'Alt+3',
                    fourth: 'Alt+4'
                }
            },
            {
                id: '588241',
                name: 'V Asistent',
                role: 'Guide',
                knowledgeScope: 'Controlled',
                similarityThreshold: 80,
                communicationStyle: 'Formal',
                behavior: 'Creative',
                answerLength: 90,
                selectedLanguages: [{ name: 'French', locale: 'fr-FR', voice: 'Male' }],
                shortcuts: {
                    first: 'Alt+1',
                    second: 'Alt+2',
                    third: 'Alt+3',
                    fourth: 'Alt+4'
                }
            },
            {
                id: '5245551',
                name: 'S Asistent',
                role: 'Guide',
                knowledgeScope: 'Controlled',
                similarityThreshold: 80,
                communicationStyle: 'Formal',
                behavior: 'Creative',
                answerLength: 60,
                selectedLanguages: [{ name: 'French', locale: 'fr-FR', voice: 'Female' }],
                shortcuts: {
                    first: 'Alt+1',
                    second: 'Alt+2',
                    third: 'Alt+3',
                    fourth: 'Alt+4'
                }
            },
            {
                id: '544241',
                name: 'L Asistent',
                role: 'Guide',
                knowledgeScope: 'Controlled',
                similarityThreshold: 80,
                communicationStyle: 'Formal',
                behavior: 'Creative',
                answerLength: 40,
                selectedLanguages: [{ name: 'French', locale: 'fr-FR', voice: 'Male' }],
                shortcuts: {
                    first: 'Alt+1',
                    second: 'Alt+2',
                    third: 'Alt+3',
                    fourth: 'Alt+4'
                }
            },
            {
                id: '577777241',
                name: 'Z Asistent',
                role: 'Guide',
                knowledgeScope: 'Controlled',
                similarityThreshold: 80,
                communicationStyle: 'Formal',
                behavior: 'Creative',
                answerLength: 150,
                selectedLanguages: [{ name: 'French', locale: 'fr-FR', voice: 'Male' }],
                shortcuts: {
                    first: 'Alt+1',
                    second: 'Alt+2',
                    third: 'Alt+3',
                    fourth: 'Alt+4'
                }
            }]
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Intercept login request
        if (req.url.includes('/auth/login')) {
            const username = req.body.get('username');
            const password = req.body.get('password');

            if (username === 'milica' && password === '123321') {
                const mockResponse = new HttpResponse({
                    status: 200,
                    body: {
                        token: 'dummy-token-12345'
                    }
                });

                return of(mockResponse).pipe(delay(2000));
            } else {
                const mockResponse = new HttpResponse({
                    status: 404
                });

                return of(mockResponse).pipe(delay(2000));
            }
        }

        // Intercept forgot password request
        if (req.url.includes('/auth/forgot-password')) {
            const email = req.body.get('email');

            if (email === 'milica@gmail.com') {
                const mockResponse = new HttpResponse({
                    status: 200,
                    body: { message: 'Password reset link sent successfully.' }
                });

                return of(mockResponse).pipe(delay(2000));
            } else {
                const mockResponse = new HttpResponse({
                    status: 404,
                    statusText: 'Not Found'
                });

                return of(mockResponse).pipe(delay(2000));
            }
        }

        // Intercept assistant creation request
        if (req.url.includes('/assistant/create')) {
            // Request assistant
            const newAssistant: Assistant = req.body;

            // Dummy assistant created
            this.assistants.push(this.assistant);

            const mockResponse = new HttpResponse({
                status: 200,
                body: {
                    message: 'Assistant successfully created.',
                    designs: [
                        { id: 'design-1', link: 'https://example.com/design1' },
                        { id: 'design-2', link: 'https://example.com/design2' },
                        { id: 'design-3', link: 'https://example.com/design3' },
                        { id: 'design-4', link: 'https://example.com/design4' }
                    ]
                }
            });

            return of(mockResponse).pipe(delay(3000));
        }

        if (req.url.includes('/assistant/get/all')) {
            // AssistantDTO list with just id and name of assistant

            const simplifiedAssistants = this.assistants.map(assistant => ({
                id: assistant.id,
                name: assistant.name
            }));

            const mockResponse = new HttpResponse({
                status: 200,
                body: simplifiedAssistants
            });

            return of(mockResponse).pipe(delay(2000));
        }

        const matchGetAssistant = req.url.match(/\/assistant\/(\d+)\/get/);
        if (matchGetAssistant) {
            const id = matchGetAssistant[1];
            const assistant = this.assistants.find(assistant => assistant.id === id);

            const mockResponse = new HttpResponse({
                status: 200,
                body: assistant
            });

            return of(mockResponse).pipe(delay(2000));
        }

        const matchUpdateAssistant = req.url.match(/\/assistant\/(\d+)\/update/);

        // Simulation getAssistant endpoint, its can update just Assistant 1 with id 5241
        if (matchUpdateAssistant) {

            const mockResponse = new HttpResponse({
                status: 200,
                body: { message: 'Assistant successfully updated.' }
            });

            return of(mockResponse).pipe(delay(2000));  // Simulate network delay
        }

        return next.handle(req);
    }
}
