import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-lost-connection',
    templateUrl: './lost-connection.component.html',
    styleUrl: './lost-connection.component.scss'
})
export class LostConnectionComponent {
    companyLogo: string = "assets/icons/exafyLogo.svg"
    lostConnectionImage: string = "assets/images/lostConnection.png"
    lostConnectionName: string = "Lost Connection"
    lostConnectionDescription: string = "You have lost connection with system"
    reloadButton: string = "Reload"

    constructor (
        //private socket: WebSocketService,
        private title: Title) {
        this.title.setTitle('Lost Connection');
    }

    async reload() {
        // TODO this needs to be discussed
        //await this.socket.reconnection();
        //this.socket.setNetworkLost(false);
    }
}
