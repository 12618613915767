import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { SharedModule } from "../../shared/shared.module";
import { RouterLink } from "@angular/router";
import { MatSidenav, MatSidenavContainer, MatSidenavModule } from "@angular/material/sidenav";

import { LoginComponent } from "./login/login.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { LostConnectionComponent } from "./lost-connection/lost-connection.component";
import { LoadingComponent } from "./loading/loading.component";

@NgModule({
    declarations: [
        LoginComponent,
        NotFoundComponent,
        LostConnectionComponent,
        LoadingComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgOptimizedImage,
        MatTooltipModule,
        MatButtonModule,
        SharedModule,
        RouterLink,
        MatSidenavContainer,
        MatSidenavModule,
        MatSidenav
    ]
})
export class NoAuthPagesModule { }
